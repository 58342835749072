import PersonIcon from "@mui/icons-material/Person";
import startCase from "lodash/startCase";
import { countries, countryStates } from "src/config/countries";

export const stringToAvatar = (user) => {
  if (user.firstname && user.lastname) {
    return { children: user.firstname[0].toUpperCase() + user.lastname[0].toUpperCase() };
  }
  if (user.email) return { children: user.email[0].toUpperCase() };
  return { children: <PersonIcon /> };
};

export const getDisplayName = (accountItem) => {
  const { company, app_user: userInfo, wallets, account } = accountItem;

  const fullName = startCase(`${userInfo.firstname} ${userInfo.lastname}`.toLowerCase());
  if (account?.company_id) {
    return `${company.name} (${fullName})`;
  }
  if (userInfo.firstname) return fullName;
  if (userInfo.email) return userInfo.email;
  return wallets?.[0]?.wallet_address;
};

export const shortenWalletAddress = (walletAddress) => {
  if (!walletAddress) return "";
  return `${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`;
};

export const getUserCountry = (accountItem, isIndividual) => {
  try {
    const { company: companyInfo, app_user: userInfo, account } = accountItem;
    const isCompany = !isIndividual && Boolean(account?.company_id);

    const countryCode = isCompany ? companyInfo.country_code : userInfo.country_code;
    const countryName = countryCode ? countries.find(({ value }) => value === countryCode)?.text : "";

    // eslint-disable-next-line no-nested-ternary
    const stateCode = countryCode === "US" ? (isCompany ? companyInfo.state_code : userInfo.state_code) : "";
    const stateName = stateCode ? countryStates.find(({ value }) => value === stateCode)?.text : "";

    const flag = countryCode ? `https://flagcdn.com/${countryCode.toLowerCase()}.svg` : "";

    return { countryCode, countryName, stateCode, stateName, flag };
  }
  catch (error) {
    console.error("[user] cannot retrieve user country: ", error);
    throw error;
  }
};

export const isProhibited = (accountItem, portalItem) => {
  if (!portalItem.jurisdictions?.prohibited_countries?.length) return false;

  const { company: companyInfo, app_user: userInfo, account } = accountItem;
  const isCompany = Boolean(account.company_id);

  if (isCompany) {
    const prohibitedOwner = [userInfo, companyInfo, ...(companyInfo.beneficial_owners || [])].find(({ country_code: countryCode }) => {
      if (!countryCode) return false;
      return portalItem.jurisdictions?.prohibited_countries?.includes(countryCode);
    });
    return prohibitedOwner?.country_code || false;
  }

  return portalItem.jurisdictions?.prohibited_countries?.includes(userInfo.country_code) ? userInfo.country_code : false;
};

export const checkIsRegD = (portalItem, accountItem) => {
  const { jurisdictions } = portalItem;
  const { countryCode } = getUserCountry(accountItem);

  if (jurisdictions?.accredited_countries?.includes("*")) return true;
  return countryCode && jurisdictions?.accredited_countries?.includes(countryCode);
};
