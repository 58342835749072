import { getAccessToken } from "@privy-io/react-auth";
import axios from "axios";

import { config_apiEndpoint } from "src/config";

export const SERVICE_1 = "service1";
export const SERVICE_2 = "service2";
export const SERVICE_ADMIN = "admin";
export const SERVICE_ADMIN_2 = "admin2";

export const callLambda = async ({ method, service, url, body = {}, queryStringParameters = {}, unauthed = false }) => {
  const accessToken = await getAccessToken();
  const headers = unauthed ? {} : { Authorization: `Bearer ${accessToken}` };
  const { data } = await axios({
    url    : `${config_apiEndpoint}/${service}/${url}`,
    method,
    headers,
    params : {
      ...queryStringParameters,
      t: Date.now()
    },
    data: body,
  });
  return data;
};

export const fetchAllResult = async ({ service, url }) => {
  const result = [];
  let lastItemKey;

  do {
    const response = await callLambda({
      method : "POST",
      service,
      url,
      body   : {
        pageLimit         : 10000,
        ExclusiveStartKey : lastItemKey
      }
    });

    result.push(...response.Items);
    lastItemKey = response.LastEvaluatedKey;
  } while (lastItemKey);

  return result;
};

export const uploadFileToS3 = async (portalId, data, filename, type = "image/png") => {
  const { signedUrl, fileUrl } = await callLambda({
    method                : "post",
    service               : SERVICE_ADMIN,
    url                   : "getAdminPresignedUrl",
    body                  : { contentType: type, key: filename },
    queryStringParameters : { portalId }
  });
  await axios({
    data,
    method  : "PUT",
    url     : signedUrl,
    headers : { "Content-Type": type }
  });
  return fileUrl;
};

export const addNewPrivateAsset = async ({ portalId, type, data, captableAiId, name, seriesId, contentType = "image/png" }) => {
  const { signedUrl, id } = await callLambda({
    method                : "post",
    service               : SERVICE_1,
    url                   : "addNewPrivateAsset",
    body                  : { type, name, seriesId, contentType, captableAiId },
    queryStringParameters : { portalId }
  });

  await axios({
    data,
    method  : "PUT",
    url     : signedUrl,
    headers : { "Content-Type": contentType }
  });

  return id;
};

export const injectS3Url = async (portalId, obj) => {
  if (obj instanceof Blob) {
    return uploadFileToS3(portalId, obj, `${Date.now()}/${obj.name}`, obj.type);
  }
  if (obj && typeof obj === "object") {
    const newItem = Array.isArray(obj) ? [] : {};
    const promises = [];
    for (const itemKey of Object.keys(obj)) {
      promises.push(injectS3Url(portalId, obj[itemKey]).then((url) => {
        newItem[itemKey] = url;
      }));
    }
    await Promise.all(promises);
    return newItem;
  }
  return obj;
};

export const getS3AssetURLRedirect = (token, portalId, id) => `${config_apiEndpoint}/${SERVICE_1}/getPreSignedReadUrlRedirect?id=${id}&authToken=${token}&portalId=${portalId}`;

export const getS3AssetURLImagesRedirect = (token, portalId, id, key) => `${config_apiEndpoint}/${SERVICE_1}/getPreSignedReadUrlImagesRedirect?id=${id}&authToken=${token}&portalId=${portalId}&key=${key}`;
