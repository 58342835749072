import useSWR from "swr";

import { SERVICE_ADMIN, callLambda } from "src/utils/lambda";

const getTasks = query => callLambda({
  method                : "get",
  service               : SERVICE_ADMIN,
  url                   : "getTasks",
  queryStringParameters : query,
});

/** SWR Functions */
export const useGetTasks = ({ portalId }) => useSWR(portalId ? [portalId, "tasks"] : null, () => getTasks({ portalId }));
