import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import { APP_STAGE } from "src/constants";
import { config_REACT_APP_STAGE } from "src/config";

import { rootReducer } from "./rootReducer";

const store = configureStore({
  reducer    : rootReducer,
  middleware : (getDefaultMiddleware) => {
    const defaultMiddleWare =  getDefaultMiddleware();
    if (config_REACT_APP_STAGE !== APP_STAGE.PRODUCTION) {
      defaultMiddleWare.push(logger);
    }
    return defaultMiddleWare;
  }
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
