import useSWR from "swr";

import { SERVICE_ADMIN, callLambda } from "src/utils/lambda";
import { SWR_GET_ADMIN_USERS_KEY, SWR_GET_PORTAL_PRIVATE_KEY, SWR_GET_RISK_FACTORS_KEY, SWR_GET_SERIES_KEY, SWR_GET_SERIES_NAV_ITEMS_KEY, SWR_GET_FUNDRAISING_SERIES_KEY } from "./swr_keys";

const getAdminContent = ({ portalId, pageName }) => callLambda({
  method                : "get",
  service               : SERVICE_ADMIN,
  url                   : "getAdminContent",
  queryStringParameters : { portalId, pageName },
});

export const useGetRiskFactors = ({ portalId }) => useSWR(SWR_GET_RISK_FACTORS_KEY(portalId), () => getAdminContent({ portalId, pageName: "risk_factors" }));

export const useGetPortalPrivate = ({ portalId }) => useSWR(SWR_GET_PORTAL_PRIVATE_KEY(portalId), () => getAdminContent({ portalId, pageName: "portal_private" }));

export const useGetAdminUsers = ({ portalId }) => useSWR(SWR_GET_ADMIN_USERS_KEY(portalId), () => getAdminContent({ portalId, pageName: "admin_users" }));

export const useGetSeriesItem = ({ portalId }) => useSWR(portalId ? [portalId, "seriesItem"] : null, () => getAdminContent({ portalId, pageName: "seriesItem" }));

export const useGetSeriesItems = ({ portalId }) => useSWR(SWR_GET_SERIES_KEY(portalId), () => getAdminContent({ portalId, pageName: "seriesItems" }));

export const useGetSeriesNavItems = ({ portalId }) => useSWR(SWR_GET_SERIES_NAV_ITEMS_KEY(portalId), () => getAdminContent({ portalId, pageName: "seriesNavItems" }));

export const useGetFundraisingSeriesItems = ({ portalId }) => useSWR(SWR_GET_FUNDRAISING_SERIES_KEY(portalId), () => getAdminContent({ portalId, pageName: "fundRaisingItems" }));
