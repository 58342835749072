import { Suspense, useEffect, Component } from "react";
import { useRoutes } from "react-router-dom";
import { PrivyProvider } from "@privy-io/react-auth";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import PropTypes from "prop-types";

import loadIntercom from "src/utils/intercom";
import { createTheme } from "./theme";
import { PrivyContextProvider, PrivyContext } from "./contexts/PrivyContext";
import { config_privyAppId, config_intercomAppId } from "./config";
import ReloadPage from "./pages/Error/ReloadPage";
import routes from "./routes";
import Messenger from "./utils/messenger";
import DialogWrapper from "./DialogWrapper";
import "./styles.less";
import FairmintLogoLoadingSpinner from "./components/FairmintLogoLoadingSpinner";
import useGlobalLoading from "./hooks/use-globalloading";

const App = ({ hasError }) => {
  const isSmallScreen = useMediaQuery("(max-width: 1800px)");
  const { loading: initialLoading } = useGlobalLoading();

  useEffect(() => {
    loadIntercom(config_intercomAppId);
  }, []);

  useEffect(() => {
    if (isSmallScreen) Messenger.showWidget(false);
    else Messenger.showWidget();
  }, [isSmallScreen]);

  const content = useRoutes(routes);

  const muiTheme = createTheme({
    colorPreset         : "indigo",
    contrast            : "high",
    direction           : "ltr",
    paletteMode         : "light",
    responsiveFontSizes : true,
  });

  // Render
  return (
    <Suspense>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline>
          <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
              vertical   : "top",
              horizontal : "right",
            }}
            autoHideDuration={3000}
          >
            <PrivyContextProvider>
              <PrivyContext.Consumer>
                {({ loginMethods }) => (
                  <PrivyProvider
                    appId={config_privyAppId}
                    onSuccess={() => {}}
                    config={{
                      loginMethods,
                      embeddedWallets: {
                        noPromptOnSignature: true
                      },
                      appearance: {
                        theme                : "light",
                        showWalletLoginFirst : false
                      },
                    }}
                  >
                    {initialLoading && <FairmintLogoLoadingSpinner />}
                    {hasError ? <ReloadPage /> : content}
                    <DialogWrapper />
                  </PrivyProvider>
                )}
              </PrivyContext.Consumer>
            </PrivyContextProvider>
          </SnackbarProvider>
        </CssBaseline>
      </ThemeProvider>
    </Suspense>
  );
};

App.propTypes = {
  hasError: PropTypes.bool,
};

class ViewWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error("[studio app] general error: ", error);
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    return <App hasError={hasError} {...this.props} />;
  }
}

App.propTypes = {
  hasError: PropTypes.bool,
};

export default ViewWrapper;
