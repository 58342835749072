import { get } from "lodash";

export const exactNumberWithCommas = x => Intl.NumberFormat("en-US", { maximumFractionDigits: get(x, "length", 20) }).format(x || 0);

export const numberWithCommas = x => Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(x || 0);

export const numberWithFixedDecimal = (x, n) => Intl.NumberFormat("en-US", { maximumFractionDigits: n || 2, minimumFractionDigits: n || 2 }).format(x || 0);

export const shortenNumber = value => new Intl.NumberFormat("en-US", {
  notation                 : "compact",
  compactDisplay           : "short",
  maximumSignificantDigits : 3,
}).format(value);
