import { usePrivy } from "@privy-io/react-auth";
import PropTypes from "prop-types";
import { Box, Button, Divider, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import { ROUTE_PATH } from "src/constants";
import { RouterLink } from "src/components";
import { useRouter } from "src/hooks";
import Messenger from "src/utils/messenger";

export const AccountPopover = (props) => {
  const router = useRouter();
  const { anchorEl, onClose, open,  userItem, portalItem, ...other } = props;
  const { user, logout } = usePrivy();

  const handleLogout = async () => {
    try {
      await logout();
      onClose?.();
    }
    catch (err) {
      console.error("[account] cannot log user out: ", err);
    }
  };

  if (!anchorEl)  return null;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal : "center",
        vertical   : "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" noWrap sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {userItem.firstname} {userItem.lastname}
        </Typography>
        <Typography color="text.secondary" variant="body2" noWrap sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {userItem.email || user.google?.email || user.email?.address}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={`/p/${portalItem.id}/${ROUTE_PATH.SETTINGS}`}
          onClick={onClose}
          sx={{
            borderRadius : 1,
            px           : 1,
            py           : 0.5,
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">Settings</Typography>} />
        </ListItemButton>
        <ListItemButton
          onClick={() => Messenger.showMessenger()}
          sx={{
            borderRadius : 1,
            px           : 1,
            py           : 0.5,
          }}
        >
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body2">Contact Support</Typography>} />
        </ListItemButton>
        {userItem.allow_multiple_portals && (
          <ListItemButton
            onClick={() => router.replace(`/portals`)}
            sx={{
              borderRadius : 1,
              px           : 1,
              py           : 0.5,
            }}
          >
            <ListItemIcon>
              <SwapHorizIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body2">Switch portal</Typography>} />
          </ListItemButton>
        )}
      </Box>

      <Divider sx={{ my: "0 !important" }} />
      <Box
        sx={{
          display        : "flex",
          p              : 1,
          justifyContent : "center",
        }}
      >
        <Button onClick={handleLogout} size="small">
          Logout
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl   : PropTypes.any,
  onClose    : PropTypes.func,
  open       : PropTypes.bool,
  userItem   : PropTypes.object,
  portalItem : PropTypes.object,
};
