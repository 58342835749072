import { useState, createContext } from "react";
import PropTypes from "prop-types";

export const PrivyContext = createContext({
  loginMethods    : ["email"],
  setLoginMethods : () => {},
});

export const PrivyContextProvider = ({ children }) => {
  const [loginMethods, setLoginMethods] = useState(["email"]);

  return (
    <PrivyContext.Provider
      value={{ loginMethods, setLoginMethods }}
    >
      {children}
    </PrivyContext.Provider>
  );
};

PrivyContextProvider.propTypes = {
  children: PropTypes.node
};
