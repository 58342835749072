import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

/**
 * This is a wrapper over `react-router/useNavigate` hook.
 */
const useRouter = () => {
  const navigate = useNavigate();

  return useMemo(() => ({
    back     : () => navigate(-1),
    forward  : () => navigate(1),
    refresh  : () => navigate(0),
    push     : (href, options) => navigate(href, options),
    replace  : href => navigate(href, { replace: true }),
    open     : href => window.open(href, "_blank", "noopener noreferrer"),
    prefetch : () => { }
  }), [navigate]);
};

export default useRouter;
