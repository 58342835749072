import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/**
 * This is an adapted for `react-router-dom/link` component.
 * We use this to help us maintain consistency between CRA and Next.js
 */
const RouterLink = forwardRef((props, ref) => {
  const { href, ...other } = props;

  return (
    <Link
      ref={ref}
      to={href}
      {...other}
    />
  );
});

RouterLink.propTypes = {
  href: PropTypes.string
};

export default RouterLink;
