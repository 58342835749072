import PropTypes from "prop-types";

import {
  Box,
  Popover,
  Typography
} from "@mui/material";

import Tasks from "src/components/Tasks";

export const TaskPopover = ({ anchorEl, onClose, open = false, taskItems, portalItem }) => (
  <Popover
    anchorEl={anchorEl}
    anchorOrigin={{
      horizontal : "left",
      vertical   : "bottom",
    }}
    disableScrollLock
    onClose={onClose}
    open={open}
    PaperProps={{ sx: { width: 380 } }}
    keepMounted
  >
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Tasks</Typography>
    </Box>
    {/* {renderTasks()} */}
    <Tasks taskItems={taskItems} onClose={onClose} portalItem={portalItem} />
  </Popover>
);
TaskPopover.propTypes = {
  anchorEl   : PropTypes.any,
  onClose    : PropTypes.func,
  open       : PropTypes.bool,
  taskItems  : PropTypes.array,
  portalItem : PropTypes.object,
};
