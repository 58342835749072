import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";

import { SERVICE_ADMIN, callLambda, injectS3Url } from "src/utils/lambda";
import { SWR_GET_ACCOUNT_INFO_KEY } from "./swr_keys";
import { updateContent } from "./updateContent";

export const useGetAdminAccountInfo = (userId, portalId) => useSWR(SWR_GET_ACCOUNT_INFO_KEY(userId, portalId), () => callLambda({
  method                : "get",
  service               : SERVICE_ADMIN,
  url                   : "getAdminAccountInfo",
  queryStringParameters : { portalId },
}));

export const useUpdateAdminAccount = (userId, portalId) => useSWRMutation(
  [userId, portalId, "updateAccount"],
  async (url, { arg }) => {
    const { actionName, payload } = arg;
    const contentPayload = await injectS3Url(portalId, payload);
    const result = await updateContent({ portalId, actionName, payload: contentPayload });
    return result;
  },
  {
    onSuccess: ({ portalItem }) => mutate(
      SWR_GET_ACCOUNT_INFO_KEY(userId, portalId),
      prev => ({ ...prev, portalItem }),
      { revalidate: false }
    ),
  }
);
