import { useMemo } from "react";
import { usePrivy } from "@privy-io/react-auth";

import { useGetAdminUserInfo } from "src/services/getAdminUserInfo";

export default WrappedComponent => (props) => {
  const { user } = usePrivy();

  const { data: adminUserInfo } = useGetAdminUserInfo(user.id);
  const userItem = useMemo(() => adminUserInfo?.userItem, [adminUserInfo]);

  if (!userItem) return null;

  return <WrappedComponent {...props} userItem={userItem} />;
};
