import { useCallback, useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import PropTypes from "prop-types";

import { ROUTE_PATH } from "src/constants";
import { usePathname } from "src/hooks";
import { useAddWallet } from "src/services/addWallet";
import { useGetAdminUserInfo } from "src/services/getAdminUserInfo";
import Messenger from "src/utils/messenger";

const Layout = ({ children }) => {
  const { ready, user, createWallet } = usePrivy();
  const pathname = usePathname();
  const { portalId } = useParams();

  const { data: adminUserInfo } = useGetAdminUserInfo(user.id);
  const userItem = useMemo(() => adminUserInfo?.userItem, [adminUserInfo]);

  const { trigger: addWallet } = useAddWallet();

  const handleCreateWallet = useCallback(async () => {
    if (!ready) {
      console.info("[privy] not ready yet");
      return;
    }
    if (user.wallet) {
      console.info("[privy] user already has a wallet");
      return;
    }
    const wallet = await createWallet();
    addWallet({
      wallet_address : wallet.address,
      wallet_type    : wallet.walletClientType
    });
  }, [createWallet, addWallet, ready, user.wallet]);

  useEffect(() => {
    if (userItem && user) {
      const wallets = user.linkedAccounts?.filter(o => o.type === "wallet");
      const privyWallet = wallets?.find(o => o.walletClientType === "privy");
      if (!privyWallet) {
        handleCreateWallet();
      }
    }
  }, [userItem, user, handleCreateWallet]);

  useEffect(() => {
    if (userItem) {
      Messenger.loadMessenger(userItem);
    }
  }, [userItem]);

  if (!userItem) return null;

  if (!portalId && pathname !== ROUTE_PATH.PORTALS) {
    return <Navigate to={ROUTE_PATH.PORTALS} />;
  }

  return <>{children}</>;
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
