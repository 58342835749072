import { usePrivy } from "@privy-io/react-auth";
import { useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";

import { ROUTE_PATH } from "src/constants";
import { useGetAdminAccountInfo } from "src/services/getAdminAccountInfo";

export default WrappedComponent => (props) => {
  const { user } = usePrivy();
  const { portalId } = useParams();

  const { data: adminAccountInfo, error } = useGetAdminAccountInfo(user.id, portalId);
  const { accountItem, portalItem } = useMemo(() => adminAccountInfo || {}, [adminAccountInfo]);

  if (!accountItem || !portalItem) return null;
  if (error) return <Navigate to={ROUTE_PATH.PORTALS} />;

  return <WrappedComponent {...props} accountItem={accountItem} portalItem={portalItem} />;
};
