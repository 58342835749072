import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/material/styles";
import withAccountInfo from "src/hoc/withAccountInfo";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";

const SIDE_NAV_WIDTH = 280;
const SIDE_NAV_SMALL_WIDTH = 235;

const VerticalLayoutRoot = styled("div")(({ theme }) => ({
  display                      : "flex",
  flex                         : "1 1 auto",
  maxWidth                     : "100%",
  [theme.breakpoints.up("lg")] : {
    paddingLeft: SIDE_NAV_SMALL_WIDTH
  },
  [theme.breakpoints.up("xl")]: {
    paddingLeft: SIDE_NAV_WIDTH
  },
}));

const VerticalLayoutContainer = styled("div")({
  display       : "flex",
  flex          : "1 1 auto",
  flexDirection : "column",
  width         : "100%"
});

const VerticalLayout = (props) => {
  const { children, sections, navColor, portalItem } = props;
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();

  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>
          {lgUp && (
            <SideNav
              color={navColor}
              sections={sections}
              portalItem={portalItem}
            />
          )}
          {!lgUp && (
            <MobileNav
              color={navColor}
              onClose={mobileNav.handleClose}
              open={mobileNav.open}
              sections={sections}
              isFreePlan={!portalItem.plan || portalItem.plan === "free"}
            />
          )}
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children   : PropTypes.node,
  navColor   : PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections   : PropTypes.array,
  portalItem : PropTypes.object,
};

export default withAccountInfo(VerticalLayout);
