import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { init as SentryInit, replayIntegration } from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { browserTracingIntegration } from "@sentry/browser";
import TagManager from "react-gtm-module";
import { SWRConfig } from "swr";
import { Toaster } from "react-hot-toast";

import store from "./store";
import {
  config_REACT_APP_STAGE,
  config_gtmId,
  config_sentryDSN
} from "./config";
import App from "./App";

SentryInit({
  environment  : config_REACT_APP_STAGE,
  dsn          : config_sentryDSN,
  integrations : [
    browserTracingIntegration(),
    captureConsoleIntegration(["error"]),
    replayIntegration({
      maskAllText   : false,
      blockAllMedia : false,
    }),
  ],
  sendDefaultPii           : true,
  tracesSampleRate         : 1.0,
  replaysSessionSampleRate : 0.1,
  replaysOnErrorSampleRate : 1.0,
  beforeSend(event) {
    // Check if the hostname is localhost
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
});

if (config_gtmId) {
  TagManager.initialize({ gtmId: config_gtmId });
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <SWRConfig value={{
          revalidateIfStale     : false,
          revalidateOnFocus     : false,
          revalidateOnReconnect : false,
          errorRetryCount       : 0
        }}
        >
          <App />
          <Toaster />
        </SWRConfig>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);
