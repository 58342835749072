export const countries = [
  { text: "Afghanistan", value: "AF", key: "AF" },
  { text: "Aland Islands", value: "AX", key: "AX" },
  { text: "Albania", value: "AL", key: "AL" },
  { text: "Algeria", value: "DZ", key: "DZ" },
  { text: "AndorrA", value: "AD", key: "AD" },
  { text: "Angola", value: "AO", key: "AO" },
  { text: "Anguilla", value: "AI", key: "AI" },
  { text: "Antarctica", value: "AQ", key: "AQ" },
  { text: "Antigua and Barbuda", value: "AG", key: "AG" },
  { text: "Argentina", value: "AR", key: "AR" },
  { text: "Armenia", value: "AM", key: "AM" },
  { text: "Aruba", value: "AW", key: "AW" },
  { text: "Australia", value: "AU", key: "AU" },
  { text: "Austria", value: "AT", key: "AT" },
  { text: "Azerbaijan", value: "AZ", key: "AZ" },
  { text: "Bahamas", value: "BS", key: "BS" },
  { text: "Bahrain", value: "BH", key: "BH" },
  { text: "Bangladesh", value: "BD", key: "BD" },
  { text: "Barbados", value: "BB", key: "BB" },
  { text: "Belarus", value: "BY", key: "BY" },
  { text: "Belgium", value: "BE", key: "BE" },
  { text: "Belize", value: "BZ", key: "BZ" },
  { text: "Benin", value: "BJ", key: "BJ" },
  { text: "Bermuda", value: "BM", key: "BM" },
  { text: "Bhutan", value: "BT", key: "BT" },
  { text: "Bolivia", value: "BO", key: "BO" },
  { text: "Bosnia and Herzegovina", value: "BA", key: "BA" },
  { text: "Botswana", value: "BW", key: "BW" },
  { text: "Bouvet Island", value: "BV", key: "BV" },
  { text: "Brazil", value: "BR", key: "BR" },
  { text: "British Indian Ocean Territory", value: "IO", key: "IO" },
  { text: "Brunei Darussalam", value: "BN", key: "BN" },
  { text: "Bulgaria", value: "BG", key: "BG" },
  { text: "Burkina Faso", value: "BF", key: "BF" },
  { text: "Burundi", value: "BI", key: "BI" },
  { text: "Cambodia", value: "KH", key: "KH" },
  { text: "Cameroon", value: "CM", key: "CM" },
  { text: "Canada", value: "CA", key: "CA" },
  { text: "Cape Verde", value: "CV", key: "CV" },
  { text: "Cayman Islands", value: "KY", key: "KY" },
  { text: "Central African Republic", value: "CF", key: "CF" },
  { text: "Chad", value: "TD", key: "TD" },
  { text: "Chile", value: "CL", key: "CL" },
  { text: "China", value: "CN", key: "CN" },
  { text: "Christmas Island", value: "CX", key: "CX" },
  { text: "Cocos (Keeling) Islands", value: "CC", key: "CC" },
  { text: "Colombia", value: "CO", key: "CO" },
  { text: "Comoros", value: "KM", key: "KM" },
  { text: "Congo", value: "CG", key: "CG" },
  { text: "Congo, The Democratic Republic of the", value: "CD", key: "CD" },
  { text: "Cook Islands", value: "CK", key: "CK" },
  { text: "Costa Rica", value: "CR", key: "CR" },
  { text: "Cote DIvoire", value: "CI", key: "CI" },
  { text: "Croatia", value: "HR", key: "HR" },
  { text: "Cuba", value: "CU", key: "CU" },
  { text: "Cyprus", value: "CY", key: "CY" },
  { text: "Czech Republic", value: "CZ", key: "CZ" },
  { text: "Denmark", value: "DK", key: "DK" },
  { text: "Djibouti", value: "DJ", key: "DJ" },
  { text: "Dominica", value: "DM", key: "DM" },
  { text: "Dominican Republic", value: "DO", key: "DO" },
  { text: "Ecuador", value: "EC", key: "EC" },
  { text: "Egypt", value: "EG", key: "EG" },
  { text: "El Salvador", value: "SV", key: "SV" },
  { text: "Equatorial Guinea", value: "GQ", key: "GQ" },
  { text: "Eritrea", value: "ER", key: "ER" },
  { text: "Estonia", value: "EE", key: "EE" },
  { text: "Ethiopia", value: "ET", key: "ET" },
  { text: "Falkland Islands (Malvinas)", value: "FK", key: "FK" },
  { text: "Faroe Islands", value: "FO", key: "FO" },
  { text: "Fiji", value: "FJ", key: "FJ" },
  { text: "Finland", value: "FI", key: "FI" },
  { text: "France", value: "FR", key: "FR" },
  { text: "French Guiana", value: "GF", key: "GF" },
  { text: "French Polynesia", value: "PF", key: "PF" },
  { text: "French Southern Territories", value: "TF", key: "TF" },
  { text: "Gabon", value: "GA", key: "GA" },
  { text: "Gambia", value: "GM", key: "GM" },
  { text: "Georgia", value: "GE", key: "GE" },
  { text: "Germany", value: "DE", key: "DE" },
  { text: "Ghana", value: "GH", key: "GH" },
  { text: "Gibraltar", value: "GI", key: "GI" },
  { text: "Greece", value: "GR", key: "GR" },
  { text: "Greenland", value: "GL", key: "GL" },
  { text: "Grenada", value: "GD", key: "GD" },
  { text: "Guadeloupe", value: "GP", key: "GP" },
  { text: "Guatemala", value: "GT", key: "GT" },
  { text: "Guernsey", value: "GG", key: "GG" },
  { text: "Guinea", value: "GN", key: "GN" },
  { text: "Guinea-Bissau", value: "GW", key: "GW" },
  { text: "Guyana", value: "GY", key: "GY" },
  { text: "Haiti", value: "HT", key: "HT" },
  { text: "Heard Island and Mcdonald Islands", value: "HM", key: "HM" },
  { text: "Holy See (Vatican City State)", value: "VA", key: "VA" },
  { text: "Honduras", value: "HN", key: "HN" },
  { text: "Hong Kong", value: "HK", key: "HK" },
  { text: "Hungary", value: "HU", key: "HU" },
  { text: "Iceland", value: "IS", key: "IS" },
  { text: "India", value: "IN", key: "IN" },
  { text: "Indonesia", value: "ID", key: "ID" },
  { text: "Iran, Islamic Republic Of", value: "IR", key: "IR" },
  { text: "Iraq", value: "IQ", key: "IQ" },
  { text: "Ireland", value: "IE", key: "IE" },
  { text: "Isle of Man", value: "IM", key: "IM" },
  { text: "Israel", value: "IL", key: "IL" },
  { text: "Italy", value: "IT", key: "IT" },
  { text: "Jamaica", value: "JM", key: "JM" },
  { text: "Japan", value: "JP", key: "JP" },
  { text: "Jersey", value: "JE", key: "JE" },
  { text: "Jordan", value: "JO", key: "JO" },
  { text: "Kazakhstan", value: "KZ", key: "KZ" },
  { text: "Kenya", value: "KE", key: "KE" },
  { text: "Kiribati", value: "KI", key: "KI" },
  { text: "Korea, Democratic People's Republic of", value: "KP", key: "KP" },
  { text: "Korea, Republic of", value: "KR", key: "KR" },
  { text: "Kuwait", value: "KW", key: "KW" },
  { text: "Kyrgyzstan", value: "KG", key: "KG" },
  { text: "Lao People's Democratic Republic", value: "LA", key: "LA" },
  { text: "Latvia", value: "LV", key: "LV" },
  { text: "Lebanon", value: "LB", key: "LB" },
  { text: "Lesotho", value: "LS", key: "LS" },
  { text: "Liberia", value: "LR", key: "LR" },
  { text: "Libyan Arab Jamahiriya", value: "LY", key: "LY" },
  { text: "Liechtenstein", value: "LI", key: "LI" },
  { text: "Lithuania", value: "LT", key: "LT" },
  { text: "Luxembourg", value: "LU", key: "LU" },
  { text: "Macao", value: "MO", key: "MO" },
  { text: "Macedonia, The Former Yugoslav Republic of", value: "MK", key: "MK" },
  { text: "Madagascar", value: "MG", key: "MG" },
  { text: "Malawi", value: "MW", key: "MW" },
  { text: "Malaysia", value: "MY", key: "MY" },
  { text: "Maldives", value: "MV", key: "MV" },
  { text: "Mali", value: "ML", key: "ML" },
  { text: "Malta", value: "MT", key: "MT" },
  { text: "Marshall Islands", value: "MH", key: "MH" },
  { text: "Martinique", value: "MQ", key: "MQ" },
  { text: "Mauritania", value: "MR", key: "MR" },
  { text: "Mauritius", value: "MU", key: "MU" },
  { text: "Mayotte", value: "YT", key: "YT" },
  { text: "Mexico", value: "MX", key: "MX" },
  { text: "Micronesia, Federated States of", value: "FM", key: "FM" },
  { text: "Moldova, Republic of", value: "MD", key: "MD" },
  { text: "Monaco", value: "MC", key: "MC" },
  { text: "Mongolia", value: "MN", key: "MN" },
  { text: "Montserrat", value: "MS", key: "MS" },
  { text: "Morocco", value: "MA", key: "MA" },
  { text: "Mozambique", value: "MZ", key: "MZ" },
  { text: "Myanmar", value: "MM", key: "MM" },
  { text: "Namibia", value: "NA", key: "NA" },
  { text: "Nauru", value: "NR", key: "NR" },
  { text: "Nepal", value: "NP", key: "NP" },
  { text: "Netherlands", value: "NL", key: "NL" },
  { text: "Netherlands Antilles", value: "AN", key: "AN" },
  { text: "New Caledonia", value: "NC", key: "NC" },
  { text: "New Zealand", value: "NZ", key: "NZ" },
  { text: "Nicaragua", value: "NI", key: "NI" },
  { text: "Niger", value: "NE", key: "NE" },
  { text: "Nigeria", value: "NG", key: "NG" },
  { text: "Niue", value: "NU", key: "NU" },
  { text: "Norfolk Island", value: "NF", key: "NF" },
  { text: "Norway", value: "NO", key: "NO" },
  { text: "Oman", value: "OM", key: "OM" },
  { text: "Pakistan", value: "PK", key: "PK" },
  { text: "Palau", value: "PW", key: "PW" },
  { text: "Palestinian Territory, Occupied", value: "PS", key: "PS" },
  { text: "Panama", value: "PA", key: "PA" },
  { text: "Papua New Guinea", value: "PG", key: "PG" },
  { text: "Paraguay", value: "PY", key: "PY" },
  { text: "Peru", value: "PE", key: "PE" },
  { text: "Philippines", value: "PH", key: "PH" },
  { text: "Pitcairn", value: "PN", key: "PN" },
  { text: "Poland", value: "PL", key: "PL" },
  { text: "Portugal", value: "PT", key: "PT" },
  { text: "Qatar", value: "QA", key: "QA" },
  { text: "Reunion", value: "RE", key: "RE" },
  { text: "Romania", value: "RO", key: "RO" },
  { text: "Russian Federation", value: "RU", key: "RU" },
  { text: "Rwanda", value: "RW", key: "RW" },
  { text: "Saint Helena", value: "SH", key: "SH" },
  { text: "Saint Kitts and Nevis", value: "KN", key: "KN" },
  { text: "Saint Lucia", value: "LC", key: "LC" },
  { text: "Saint Pierre and Miquelon", value: "PM", key: "PM" },
  { text: "Saint Vincent and the Grenadines", value: "VC", key: "VC" },
  { text: "Samoa", value: "WS", key: "WS" },
  { text: "San Marino", value: "SM", key: "SM" },
  { text: "Sao Tome and Principe", value: "ST", key: "ST" },
  { text: "Saudi Arabia", value: "SA", key: "SA" },
  { text: "Senegal", value: "SN", key: "SN" },
  { text: "Serbia and Montenegro", value: "CS", key: "CS" },
  { text: "Seychelles", value: "SC", key: "SC" },
  { text: "Sierra Leone", value: "SL", key: "SL" },
  { text: "Singapore", value: "SG", key: "SG" },
  { text: "Slovakia", value: "SK", key: "SK" },
  { text: "Slovenia", value: "SI", key: "SI" },
  { text: "Solomon Islands", value: "SB", key: "SB" },
  { text: "Somalia", value: "SO", key: "SO" },
  { text: "South Africa", value: "ZA", key: "ZA" },
  { text: "South Georgia and the South Sandwich Islands", value: "GS", key: "GS" },
  { text: "Spain", value: "ES", key: "ES" },
  { text: "Sri Lanka", value: "LK", key: "LK" },
  { text: "Sudan", value: "SD", key: "SD" },
  { text: "Suriname", value: "SR", key: "SR" },
  { text: "Svalbard and Jan Mayen", value: "SJ", key: "SJ" },
  { text: "Swaziland", value: "SZ", key: "SZ" },
  { text: "Sweden", value: "SE", key: "SE" },
  { text: "Switzerland", value: "CH", key: "CH" },
  { text: "Syrian Arab Republic", value: "SY", key: "SY" },
  { text: "Taiwan, Province of China", value: "TW", key: "TW" },
  { text: "Tajikistan", value: "TJ", key: "TJ" },
  { text: "Tanzania, United Republic of", value: "TZ", key: "TZ" },
  { text: "Thailand", value: "TH", key: "TH" },
  { text: "Timor-Leste", value: "TL", key: "TL" },
  { text: "Togo", value: "TG", key: "TG" },
  { text: "Tokelau", value: "TK", key: "TK" },
  { text: "Tonga", value: "TO", key: "TO" },
  { text: "Trinidad and Tobago", value: "TT", key: "TT" },
  { text: "Tunisia", value: "TN", key: "TN" },
  { text: "Turkey", value: "TR", key: "TR" },
  { text: "Turkmenistan", value: "TM", key: "TM" },
  { text: "Turks and Caicos Islands", value: "TC", key: "TC" },
  { text: "Tuvalu", value: "TV", key: "TV" },
  { text: "Uganda", value: "UG", key: "UG" },
  { text: "Ukraine", value: "UA", key: "UA" },
  { text: "United Arab Emirates", value: "AE", key: "AE" },
  { text: "United Kingdom", value: "GB", key: "GB" },
  { text: "United States", value: "US", key: "US" },
  { text: "United States Minor Outlying Islands", value: "UM", key: "UM" },
  { text: "Uruguay", value: "UY", key: "UY" },
  { text: "Uzbekistan", value: "UZ", key: "UZ" },
  { text: "Vanuatu", value: "VU", key: "VU" },
  { text: "Venezuela", value: "VE", key: "VE" },
  { text: "Viet Nam", value: "VN", key: "VN" },
  { text: "Virgin Islands, British", value: "VG", key: "VG" },
  { text: "Wallis and Futuna", value: "WF", key: "WF" },
  { text: "Western Sahara", value: "EH", key: "EH" },
  { text: "Yemen", value: "YE", key: "YE" },
  { text: "Zambia", value: "ZM", key: "ZM" },
  { text: "Zimbabwe", value: "ZW", key: "ZW" },
];

export const countryStates = [
  { text: "ALABAMA", value: "AL", key: "US-AL", country: "US" },
  { text: "ALASKA", value: "AK", key: "US-AK", country: "US" },
  { text: "AMERICAN SAMOA", value: "AS", key: "US-AS", country: "US" },
  { text: "ARIZONA", value: "AZ", key: "US-AZ", country: "US" },
  { text: "ARKANSAS", value: "AR", key: "US-AR", country: "US" },
  { text: "CALIFORNIA", value: "CA", key: "US-CA", country: "US" },
  { text: "COLORADO", value: "CO", key: "US-CO", country: "US" },
  { text: "CONNECTICUT", value: "CT", key: "US-CT", country: "US" },
  { text: "DELAWARE", value: "DE", key: "US-DE", country: "US" },
  { text: "DISTRICT OF COLUMBIA", value: "DC", key: "US-DC", country: "US" },
  { text: "FLORIDA", value: "FL", key: "US-FL", country: "US" },
  { text: "GEORGIA", value: "GA", key: "US-GA", country: "US" },
  { text: "GUAM", value: "GU", key: "US-GU", country: "US" },
  { text: "HAWAII", value: "HI", key: "US-HI", country: "US" },
  { text: "IDAHO", value: "ID", key: "US-ID", country: "US" },
  { text: "ILLINOIS", value: "IL", key: "US-IL", country: "US" },
  { text: "INDIANA", value: "IN", key: "US-IN", country: "US" },
  { text: "IOWA", value: "IA", key: "US-IA", country: "US" },
  { text: "KANSAS", value: "KS", key: "US-KS", country: "US" },
  { text: "KENTUCKY", value: "KY", key: "US-KY", country: "US" },
  { text: "LOUISIANA", value: "LA", key: "US-LA", country: "US" },
  { text: "MAINE", value: "ME", key: "US-ME", country: "US" },
  { text: "MARYLAND", value: "MD", key: "US-MD", country: "US" },
  { text: "MASSACHUSETTS", value: "MA", key: "US-MA", country: "US" },
  { text: "MICHIGAN", value: "MI", key: "US-MI", country: "US" },
  { text: "MINNESOTA", value: "MN", key: "US-MN", country: "US" },
  { text: "MISSISSIPPI", value: "MS", key: "US-MS", country: "US" },
  { text: "MISSOURI", value: "MO", key: "US-MO", country: "US" },
  { text: "MONTANA", value: "MT", key: "US-MT", country: "US" },
  { text: "NEBRASKA", value: "NE", key: "US-NE", country: "US" },
  { text: "NEVADA", value: "NV", key: "US-NV", country: "US" },
  { text: "NEW HAMPSHIRE", value: "NH", key: "US-NH", country: "US" },
  { text: "NEW JERSEY", value: "NJ", key: "US-NJ", country: "US" },
  { text: "NEW MEXICO", value: "NM", key: "US-NM", country: "US" },
  { text: "NEW YORK", value: "NY", key: "US-NY", country: "US" },
  { text: "NORTH CAROLINA", value: "NC", key: "US-NC", country: "US" },
  { text: "NORTH DAKOTA", value: "ND", key: "US-ND", country: "US" },
  { text: "NORTHERN MARIANA IS", value: "MP", key: "US-MP", country: "US" },
  { text: "OHIO", value: "OH", key: "US-OH", country: "US" },
  { text: "OKLAHOMA", value: "OK", key: "US-OK", country: "US" },
  { text: "OREGON", value: "OR", key: "US-OR", country: "US" },
  { text: "PENNSYLVANIA", value: "PA", key: "US-PA", country: "US" },
  { text: "PUERTO RICO", value: "PR", key: "US-PR", country: "US" },
  { text: "RHODE ISLAND", value: "RI", key: "US-RI", country: "US" },
  { text: "SOUTH CAROLINA", value: "SC", key: "US-SC", country: "US" },
  { text: "SOUTH DAKOTA", value: "SD", key: "US-SD", country: "US" },
  { text: "TENNESSEE", value: "TN", key: "US-TN", country: "US" },
  { text: "TEXAS", value: "TX", key: "US-TX", country: "US" },
  { text: "UTAH", value: "UT", key: "US-UT", country: "US" },
  { text: "VERMONT", value: "VT", key: "US-VT", country: "US" },
  { text: "VIRGINIA", value: "VA", key: "US-VA", country: "US" },
  { text: "VIRGIN ISLANDS", value: "VI", key: "US-VI", country: "US" },
  { text: "WASHINGTON", value: "WA", key: "US-WA", country: "US" },
  { text: "WEST VIRGINIA", value: "WV", key: "US-WV", country: "US" },
  { text: "WISCONSIN", value: "WI", key: "US-WI", country: "US" },
  { text: "WYOMING", value: "WY", key: "US-WY", country: "US" },
];
