/* eslint-disable react/no-array-index-key */
import { useEffect, useMemo, useState } from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { RouterLink, Scrollbar } from "src/components";
import { usePathname } from "src/hooks";
import Logo from "src/assets/images/default/FairmintLogo.svg";
import OneLogo from "src/assets/images/default/FairmintOneLogo.svg";
import { AutoAwesome, Add } from "@mui/icons-material";

import useGlobalDialog from "src/hooks/use-globaldialog";
import { DIALOG_NAME, ROUTE_PATH, APP_STAGE } from "src/constants";
import { config_REACT_APP_STAGE } from "src/config";
import { usePrivy } from "@privy-io/react-auth";
import { SideNavSection } from "./side-nav-section";

const SIDE_NAV_WIDTH = 280;
const SIDE_NAV_SMALL_WIDTH = 235;

const useCssVars = (color) => {
  const theme = useTheme();

  return useMemo(() => {
    switch (color) {
    case "blend-in":
      if (theme.palette.mode === "dark") {
        return {
          "--nav-bg"                       : theme.palette.background.default,
          "--nav-color"                    : theme.palette.neutral[100],
          "--nav-border-color"             : theme.palette.neutral[700],
          "--nav-logo-border"              : theme.palette.neutral[700],
          "--nav-section-title-color"      : theme.palette.neutral[400],
          "--nav-item-color"               : theme.palette.neutral[400],
          "--nav-item-hover-bg"            : "rgba(255, 255, 255, 0.04)",
          "--nav-item-active-bg"           : "rgba(255, 255, 255, 0.04)",
          "--nav-item-active-color"        : theme.palette.text.primary,
          "--nav-item-disabled-color"      : theme.palette.neutral[600],
          "--nav-item-icon-color"          : theme.palette.neutral[500],
          "--nav-item-icon-active-color"   : theme.palette.primary.main,
          "--nav-item-icon-disabled-color" : theme.palette.neutral[700],
          "--nav-item-chevron-color"       : theme.palette.neutral[700],
          "--nav-scrollbar-color"          : theme.palette.neutral[400]
        };
      }
      return {
        "--nav-bg"                       : theme.palette.background.default,
        "--nav-color"                    : theme.palette.text.primary,
        "--nav-border-color"             : theme.palette.neutral[100],
        "--nav-logo-border"              : theme.palette.neutral[100],
        "--nav-section-title-color"      : theme.palette.neutral[400],
        "--nav-item-color"               : theme.palette.text.secondary,
        "--nav-item-hover-bg"            : theme.palette.action.hover,
        "--nav-item-active-bg"           : theme.palette.action.selected,
        "--nav-item-active-color"        : theme.palette.text.primary,
        "--nav-item-disabled-color"      : theme.palette.neutral[400],
        "--nav-item-icon-color"          : theme.palette.neutral[400],
        "--nav-item-icon-active-color"   : theme.palette.primary.main,
        "--nav-item-icon-disabled-color" : theme.palette.neutral[400],
        "--nav-item-chevron-color"       : theme.palette.neutral[400],
        "--nav-scrollbar-color"          : theme.palette.neutral[900]
      };

    case "discreet":
      if (theme.palette.mode === "dark") {
        return {
          "--nav-bg"                       : theme.palette.neutral[900],
          "--nav-color"                    : theme.palette.neutral[100],
          "--nav-border-color"             : theme.palette.neutral[700],
          "--nav-logo-border"              : theme.palette.neutral[700],
          "--nav-section-title-color"      : theme.palette.neutral[400],
          "--nav-item-color"               : theme.palette.neutral[400],
          "--nav-item-hover-bg"            : "rgba(255, 255, 255, 0.04)",
          "--nav-item-active-bg"           : "rgba(255, 255, 255, 0.04)",
          "--nav-item-active-color"        : theme.palette.text.primary,
          "--nav-item-disabled-color"      : theme.palette.neutral[600],
          "--nav-item-icon-color"          : theme.palette.neutral[500],
          "--nav-item-icon-active-color"   : theme.palette.primary.main,
          "--nav-item-icon-disabled-color" : theme.palette.neutral[700],
          "--nav-item-chevron-color"       : theme.palette.neutral[700],
          "--nav-scrollbar-color"          : theme.palette.neutral[400]
        };
      }
      return {
        "--nav-bg"                       : theme.palette.neutral[50],
        "--nav-color"                    : theme.palette.text.primary,
        "--nav-border-color"             : theme.palette.divider,
        "--nav-logo-border"              : theme.palette.neutral[200],
        "--nav-section-title-color"      : theme.palette.neutral[500],
        "--nav-item-color"               : theme.palette.neutral[500],
        "--nav-item-hover-bg"            : theme.palette.action.hover,
        "--nav-item-active-bg"           : theme.palette.action.selected,
        "--nav-item-active-color"        : theme.palette.text.primary,
        "--nav-item-disabled-color"      : theme.palette.neutral[400],
        "--nav-item-icon-color"          : theme.palette.neutral[400],
        "--nav-item-icon-active-color"   : theme.palette.primary.main,
        "--nav-item-icon-disabled-color" : theme.palette.neutral[400],
        "--nav-item-chevron-color"       : theme.palette.neutral[400],
        "--nav-scrollbar-color"          : theme.palette.neutral[900]
      };

    case "evident":
      if (theme.palette.mode === "dark") {
        return {
          "--nav-bg"                       : theme.palette.neutral[800],
          "--nav-color"                    : theme.palette.common.white,
          "--nav-border-color"             : "transparent",
          "--nav-logo-border"              : theme.palette.neutral[700],
          "--nav-section-title-color"      : theme.palette.neutral[400],
          "--nav-item-color"               : theme.palette.neutral[400],
          "--nav-item-hover-bg"            : "rgba(255, 255, 255, 0.04)",
          "--nav-item-active-bg"           : "rgba(255, 255, 255, 0.04)",
          "--nav-item-active-color"        : theme.palette.common.white,
          "--nav-item-disabled-color"      : theme.palette.neutral[500],
          "--nav-item-icon-color"          : theme.palette.neutral[400],
          "--nav-item-icon-active-color"   : theme.palette.primary.main,
          "--nav-item-icon-disabled-color" : theme.palette.neutral[500],
          "--nav-item-chevron-color"       : theme.palette.neutral[600],
          "--nav-scrollbar-color"          : theme.palette.neutral[400]
        };
      }
      return {
        "--nav-bg"                       : theme.palette.neutral[800],
        "--nav-color"                    : theme.palette.common.white,
        "--nav-border-color"             : "transparent",
        "--nav-logo-border"              : theme.palette.neutral[700],
        "--nav-section-title-color"      : theme.palette.neutral[400],
        "--nav-item-color"               : theme.palette.neutral[400],
        "--nav-item-hover-bg"            : "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-bg"           : "rgba(255, 255, 255, 0.04)",
        "--nav-item-active-color"        : theme.palette.common.white,
        "--nav-item-disabled-color"      : theme.palette.neutral[500],
        "--nav-item-icon-color"          : theme.palette.neutral[400],
        "--nav-item-icon-active-color"   : theme.palette.primary.main,
        "--nav-item-icon-disabled-color" : theme.palette.neutral[500],
        "--nav-item-chevron-color"       : theme.palette.neutral[600],
        "--nav-scrollbar-color"          : theme.palette.neutral[400]
      };

    default:
      return {};
    }
  }, [theme, color]);
};

export const SideNav = (props) => {
  const { color = "evident", sections = [], portalItem } = props;
  const pathname = usePathname();
  const cssVars = useCssVars(color);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
  const { setData: setCurrentDialog } = useGlobalDialog();
  const isFreePlan = !portalItem.plan || portalItem.plan === "free";
  const { user } = usePrivy();

  const isWhitelisted = useMemo(() => portalItem.allow_captable_ai || false, [portalItem]);
  const isMasterFund = get(portalItem, "attributes.isMasterFund", false);

  // Only display the "New" button is the portal is not a Master fund or a VC.
  // If the portal is a Master fund or a VC fund, then only display the New button to Fairmint admins.
  // (So that Fairmint admins can setup SPVs / Funds series for the customer easily)
  const isVentureFund = get(portalItem, "attributes.isVentureFund", false);
  const isFairmintAdmin = config_REACT_APP_STAGE === APP_STAGE.PRODUCTION ? get(user, "email.address", null) === "techsupport@fairmint.co" : get(user, "email.address", null) === "testseries.benef@yopmail.com";
  const displayNewButton = !(isMasterFund || isVentureFund) || ((isMasterFund || isVentureFund) && isFairmintAdmin);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const keyCombination = (isMac && event.metaKey && event.key === "k") || (!isMac && event.ctrlKey && event.key === "k");
      if (keyCombination) {
        event.preventDefault();
        if (isWhitelisted) {
          setIsDialogOpen(!isDialogOpen);
          if (!isDialogOpen) {
            setCurrentDialog(DIALOG_NAME.AI, { type: "chat", portalItem, userId: user.id });
          }
          else {
            setCurrentDialog(null);
          }
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setCurrentDialog, portalItem, user.id, isDialogOpen, isWhitelisted]);

  return (
    <Drawer
      anchor="left"
      open
      id="sideNav"
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor  : "var(--nav-bg)",
          borderRightColor : "var(--nav-border-color)",
          borderRightStyle : "solid",
          borderRightWidth : 1,
          color            : "var(--nav-color)",
          width            : isSmallScreen ? SIDE_NAV_SMALL_WIDTH : SIDE_NAV_WIDTH
        }
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          height                 : "100%",
          "& .simplebar-content" : {
            height: "100%"
          },
          "& .simplebar-scrollbar:before": {
            background: "var(--nav-scrollbar-color)"
          }
        }}
        autoHide
      >
        <Stack sx={{ height: "100%" }}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ p: 3 }}
          >
            <Box
              // eslint-disable-next-line no-shadow
              component={props => <RouterLink {...props} state={{ from: pathname }} />}
              href={ROUTE_PATH.PORTALS}
              sx={{
                display : "flex",
                height  : 45,
              }}
            >
              <img src={isFreePlan ? Logo : OneLogo} alt="Fairmint logo" style={{ height: "3rem" }} />
            </Box>
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{ px: isSmallScreen ? 1.5 : 2, pb: 3 }}
          >
            {sections.map((section, index) => (
              <SideNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
          {isWhitelisted && (
            <Box sx={{ paddingTop: 2, paddingLeft: 3, paddingRight: 3, paddingBottom: 1 }}>
              <Button
                color="secondary"
                fullWidth
                startIcon={<AutoAwesome fontSize="medium" />}
                variant="contained"
                onClick={() => setCurrentDialog(DIALOG_NAME.AI, { type: "chat", portalItem, userId: user.id })}
              >
                Captable AI
                <Box component="span" sx={{ border: "1px solid", borderColor: "divider", borderRadius: 1, padding: "0 4px", marginLeft: 1 }}>
                  ⌘K
                </Box>
              </Button>
            </Box>
          )}
          {displayNewButton && (
            <Box sx={{ paddingTop: 1, paddingLeft: 3, paddingRight: 3 }}>
              <Button
                color="secondary"
                fullWidth
                startIcon={<Add fontSize="medium" />}
                variant="outlined"
                onClick={() => setCurrentDialog(DIALOG_NAME.SERIES_TYPE, { type: "new_series", portalItem })}
              >
                New
              </Button>
            </Box>
          )}
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

SideNav.propTypes = {
  color      : PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections   : PropTypes.array,
  portalItem : PropTypes.object,
};
