import PropTypes from "prop-types";
import { alpha } from "@mui/system/colorManipulator";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";

import AccountButton from "../account-button";
import NotificationsButton from "./notifications-button";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;
const SIDE_NAV_SMALL_WIDTH = 235;

export const TopNav = (props) => {
  const { onMobileNavOpen, ...other } = props;
  const lgUp = useMediaQuery(theme => theme.breakpoints.up("lg"));

  return (
    <Box
      component="header"
      sx={{
        backdropFilter  : "blur(6px)",
        backgroundColor : theme => alpha(theme.palette.background.default, 0.8),
        position        : "sticky",
        left            : {
          xl : `${SIDE_NAV_WIDTH}px`,
          lg : `${SIDE_NAV_SMALL_WIDTH}px`
        },
        top   : 0,
        width : {
          xl : `calc(100% - ${SIDE_NAV_WIDTH}px)`,
          lg : `calc(100% - ${SIDE_NAV_SMALL_WIDTH}px)`
        },
        zIndex: theme => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight : TOP_NAV_HEIGHT,
          px        : 2
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          <NotificationsButton />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
