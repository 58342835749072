import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import merge from "lodash/merge";

import { SERVICE_1, SERVICE_ADMIN, callLambda } from "src/utils/lambda";
import { SWR_GET_USER_INFO_KEY } from "./swr_keys";

export const useGetAdminUserInfo = userId => useSWR(SWR_GET_USER_INFO_KEY(userId), () => callLambda({
  method  : "get",
  service : SERVICE_ADMIN,
  url     : "getAdminUserInfo",
}));

export const useUpdateAdminUser = userId => useSWRMutation(
  [userId, "updateUser"],
  (url, { arg }) => callLambda({
    method  : "post",
    service : SERVICE_1,
    url     : "updateUser",
    body    : arg,
  }),
  {
    onSuccess: ({ userItem }) => mutate(
      SWR_GET_USER_INFO_KEY(userId),
      prev => ({ userItem: { ...merge(prev.userItem, userItem) } }),
      { revalidate: false }
    ),
  }
);
