const SeriesNavIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_22119_29683)">
      <g clipPath="url(#clip1_22119_29683)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.572 4.60057L20.7126 5.98016C21.3974 6.1607 21.9458 6.61034 22.2763 7.18262C22.6067 7.75489 22.7192 8.4532 22.5352 9.13789L19.333 21.0944C19.149 21.7756 18.7027 22.3241 18.1304 22.6545C17.5581 22.9883 16.8597 23.1007 16.175 22.9168L8.04678 20.7401C7.36204 20.5561 6.81357 20.1099 6.48313 19.5376C6.30599 19.2311 6.19357 18.8904 6.15269 18.5361H4.57882C3.87365 18.5361 3.22639 18.2466 2.75968 17.7799C2.28956 17.3098 2 16.666 2 15.9575V3.57864C2 2.87352 2.28956 2.2263 2.75627 1.75963C3.22639 1.29295 3.87365 1 4.57882 1H12.9932C13.7018 1 14.3456 1.29295 14.8124 1.75963C15.2825 2.2263 15.572 2.87352 15.572 3.57864V4.60057ZM12.9932 1.80732H4.57882C4.09167 1.80732 3.6454 2.00489 3.32518 2.32509C3.00496 2.6487 2.80397 3.09153 2.80397 3.57864V15.9575C2.80397 16.4446 3.00496 16.8909 3.32518 17.2111C3.6454 17.5313 4.09167 17.7322 4.57882 17.7322H12.9932C13.4804 17.7322 13.9266 17.5313 14.2469 17.2111C14.5671 16.8909 14.7681 16.4446 14.7681 15.9575V3.57864C14.7681 3.09153 14.5671 2.6487 14.2469 2.3285C13.9266 2.00489 13.4804 1.80732 12.9932 1.80732Z"
          fill="inherit"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_22119_29683">
        <rect width="20.6175" height="22" fill="white" transform="translate(2 1)" />
      </clipPath>
      <clipPath id="clip1_22119_29683">
        <rect width="20.6233" height="22.0048" fill="white" transform="translate(2 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default SeriesNavIcon;
