import useSWRMutation from "swr/mutation";

import { SERVICE_1, callLambda } from "src/utils/lambda";

export const useAddWallet = () => useSWRMutation("addWallet", (url, { arg }) => callLambda({
  method  : "post",
  service : SERVICE_1,
  url     : "addWallet",
  body    : arg,
}));
