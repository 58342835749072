import { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { setCurrentDialog } from "src/slices/global";

const useGlobalDialog = () => {
  const data = useSelector(state => state.global?.currentDialog);
  const dispatch = useDispatch();

  const setData = useCallback((name, props) => {
    dispatch(setCurrentDialog({ name, props }));
  }, [dispatch]);

  return { data, setData  };
};

export default useGlobalDialog;
