import { config_intercomAppId } from "src/config";

const Messenger = {
  open: null
};

Messenger.loadMessenger = (userItem, portalItem) => {
  const newSetting = {
    app_id      : config_intercomAppId,
    user_id     : userItem.id,
    user_hash   : userItem.intercom_user_hash,
    is_customer : true
  };
  window.Intercom("boot", newSetting);
  if (userItem.email) newSetting.email = userItem.email;
  if (userItem.firstname) newSetting.name = userItem.firstname + " " + userItem.lastname;
  if (userItem.phone) newSetting.phone = userItem.phone;
  if (portalItem?.company) {
    newSetting.company = {
      id       : portalItem.id,
      name     : portalItem.company.name,
      website  : portalItem.company.website,
      industry : portalItem.company.industry,
      plan     : portalItem.plan
    };
  }
  window.Intercom("update", newSetting);
};

Messenger.showMessenger = (message) => {
  try {
    window.Intercom("showNewMessage", message);
  }
  catch (err) {
    console.error("[messenger] cannot show messenger: ", err);
  }
};

Messenger.showWidget = (flag = true) => {
  if (window.Intercom) {
    window.Intercom("update", {
      hide_default_launcher: !flag
    });
  }
};

Messenger.trackEvent = (event) => {
  window.Intercom("trackEvent", event);
};

Messenger.updateLegalEntity = (companyUpdate) => {
  window.Intercom("update", {
    company: companyUpdate,
  });
};

export default Messenger;
