import { useEffect } from "react";
import useGlobalLoading from "./use-globalloading";

const useDisableFairmintSpinner = () => {
  const { disableInitialLoadingScreen: disableInitialLoading } = useGlobalLoading();

  useEffect(() => {
    disableInitialLoading();
    return () => { };
  }, [disableInitialLoading]);
};

export default useDisableFairmintSpinner;
