import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import merge from "lodash/merge";

import { SERVICE_ADMIN, callLambda } from "src/utils/lambda";
import { SWR_GET_PORTAL_PRIVATE_KEY, SWR_GET_RISK_FACTORS_KEY } from "./swr_keys";

export const updateContent = ({ portalId, actionName, payload }) => callLambda({
  method                : "post",
  service               : SERVICE_ADMIN,
  url                   : "updateContent",
  body                  : payload,
  queryStringParameters : { portalId, actionName },
});

export const useUpdateRiskFactorHeader = portalId => useSWRMutation(
  "updateRiskFactorHeader",
  (url, { arg }) => updateContent({ ...arg, portalId }),
  {
    onSuccess: (data) => {
      const { headers } = data;
      mutate(SWR_GET_RISK_FACTORS_KEY(portalId), prev => ({ riskItem: { risk_factors: { ...prev.riskItem.risk_factors, headers } } }), {
        revalidate: false,
      });
    },
  }
);

export const useAddRiskFactorCategory = portalId => useSWRMutation("addRiskFactorCategory", (url, { arg }) => updateContent({ ...arg, portalId }), {
  onSuccess: (data) => {
    mutate(
      SWR_GET_RISK_FACTORS_KEY(portalId),
      prev => ({
        riskItem: {
          risk_factors: {
            ...prev.riskItem.risk_factors,
            categories: [...prev.riskItem.risk_factors.categories, data],
          },
        },
      }),
      { revalidate: false }
    );
  },
});

export const useUpdateRiskFactorCategory = portalId => useSWRMutation(
  "updateRiskFactorCategory",
  (url, { arg }) => updateContent({ ...arg, portalId }),
  {
    onSuccess: (data) => {
      mutate(
        SWR_GET_RISK_FACTORS_KEY(portalId),
        (prev) => {
          const { categories } = prev.riskItem.risk_factors;
          const index = categories.findIndex(({ id }) => id === data.id);
          categories[index] = data;
          return {
            riskItem: {
              risk_factors: {
                ...prev.riskItem.risk_factors,
                categories,
              },
            },
          };
        },
        { revalidate: false }
      );
    },
  }
);

export const useDeleteRiskFactorCategory = portalId => useSWRMutation(
  "deleteRiskFactorCategory",
  (url, { arg }) => updateContent({ ...arg, portalId }).then((res) => {
    if (res.success) return arg.payload.id;
    return null;
  }),
  {
    onSuccess: (categoryId) => {
      mutate(
        SWR_GET_RISK_FACTORS_KEY(portalId),
        (prev) => {
          const { categories } = prev.riskItem.risk_factors;
          const index = categories.findIndex(({ id }) => id === categoryId);
          categories.splice(index, 1);

          return {
            riskItem: {
              risk_factors: {
                ...prev.riskItem.risk_factors,
                categories,
              },
            },
          };
        },
        { revalidate: false }
      );
    },
  }
);

export const useUpdatePortalPrivate = portalId => useSWRMutation(
  "updatePortalPrivate",
  (key, { arg }) => updateContent({ ...arg, portalId, actionName: key }),
  {
    onSuccess: (data) => {
      mutate(SWR_GET_PORTAL_PRIVATE_KEY(portalId), prev => ({ portalPrivate: { ...merge(prev.portalPrivate, data.portalPrivate) } }), {
        revalidate: false,
      });
    },
  }
);
