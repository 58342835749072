import PropTypes from "prop-types";
import { Avatar, Box, ButtonBase, Typography } from "@mui/material";
import get from "lodash/get";

import { usePopover } from "src/hooks";
import { stringToAvatar } from "src/utils/user";
import withAccountInfo from "src/hoc/withAccountInfo";
import withUserItem from "src/hoc/withUserItem";

import { AccountPopover } from "./account-popover";

const AccountButton = ({ userItem, portalItem }) => {
  const popover = usePopover();

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems     : "center",
          display        : "flex",
          justifyContent : "center",
          borderWidth    : 1,
          borderStyle    : "solid",
          borderColor    : "#E5E7EB",
          borderRadius   : "12px",
          background     : "#fff",
          padding        : "4px 8px"
        }}
      >
        {get(portalItem, "logos.rectangle_colored") ? (
          <Box
            component="img"
            src={get(portalItem, "logos.rectangle_colored")}
            alt="logo"
            sx={{ maxHeight: "32px", height: "100%", width: "auto" }}
          />
      ) : (
        <Typography sx={{ fontSize: 16, fontWeight: "500", color: "#000000CC" }}>
          {get(portalItem, "company.name").slice(0, 25)}
          {get(portalItem, "company.name").length > 25 ? "..." : ""}
        </Typography>
      )}
        <Box
          sx={{
            justifyContent : "center",
            alignItems     : "center",
            display        : "flex",
            marginLeft     : 0.5,
            height         : 40,
            width          : 40,
            borderRadius   : "50%"
          }}
        >
          <Avatar
            sx={{
              height : 32,
              width  : 32
            }}
            {...stringToAvatar(userItem)}
          />
        </Box>
      </Box>

      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        userItem={userItem}
        portalItem={portalItem}
      />
    </>
  );
};

AccountButton.propTypes = {
  userItem   : PropTypes.object,
  portalItem : PropTypes.object,
};

export default withUserItem(withAccountInfo(AccountButton));
