import { mainnet, optimismSepolia, optimism } from "viem/chains";

const { REACT_APP_ORG, REACT_APP_STAGE, REACT_APP_DEVELOPMENT } = process.env;

const config = {
  dev: {
    privyAppId     : "clge6cxv5001ajy08n329vdx7",
    apiEndpoint    : "https://api.series-dev.fairmint.co",
    wsEndpoint     : "wss://ws.series-dev.fairmint.co",
    intercomAppId  : "v7e5s2ll",
    captableAiUrl  : "https://dev.ai.captable.ai/api/chat", // "http://localhost:8000/api/chat", //
    etherscanOpUrl : "https://sepolia-optimism.etherscan.io",
    etherscanUrl   : "https://sepolia.etherscan.io",
    basescanUrl    : "https://sepolia.basescan.org",
    USDC           : {
      chain   : optimismSepolia,
      address : "0xf74eBB85fADE9FDB1085194335b58823B98DCd47"
    },
    smartContract: {
      chain: optimismSepolia
    },
  },
  staging: {
    privyAppId     : "clmi1x42400d9l50fqa9doz8d",
    apiEndpoint    : "https://api.series-staging.fairmint.co",
    wsEndpoint     : "wss://ws.series-staging.fairmint.co",
    intercomAppId  : "v7e5s2ll",
    captableAiUrl  : "https://dev.ai.captable.ai/api/chat",
    etherscanOpUrl : "https://sepolia-optimism.etherscan.io",
    etherscanUrl   : "https://sepolia.etherscan.io",
    basescanUrl    : "https://sepolia.basescan.org",
    USDC           : {
      chain   : optimismSepolia,
      address : "0xf74eBB85fADE9FDB1085194335b58823B98DCd47"
    },
    smartContract: {
      chain: optimismSepolia
    },
  },
  production: {
    privyAppId     : "clm7wfbpp001pmj0fd93ahj9k",
    apiEndpoint    : "https://api.series.fairmint.co",
    wsEndpoint     : "wss://ws.series.fairmint.co",
    intercomAppId  : "zvhi5494",
    gtmId          : "GTM-TVJJFD2J",
    captableAiUrl  : "https://ai.captable.ai/api/chat",
    etherscanOpUrl : "https://optimistic.etherscan.io",
    etherscanUrl   : "https://etherscan.io",
    basescanUrl    : "https://basescan.org",
    USDC           : {
      chain   : mainnet,
      address : "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
    },
    smartContract: {
      chain: optimism
    },
  },
  common: {
    sentryDSN: "https://9c8272ec3e3de280659c96ef5e717767@o320781.ingest.us.sentry.io/4507018646978560"
  }
};

const configuration = {
  ...config.common,
  ...(config[REACT_APP_STAGE]),
};

// disable console.log() and console.debug() in production
if (REACT_APP_STAGE === "production") {
  console.log = () => {};
  console.debug = () => {};
}

// common config variables
export const config_sentryDSN = configuration.sentryDSN;

// stage specific config variables
export const config_REACT_APP_STAGE = REACT_APP_STAGE;
export const config_REACT_APP_ORG = REACT_APP_ORG;
export const config_REACT_APP_DEVELOPMENT = REACT_APP_DEVELOPMENT;
export const config_privyAppId = configuration.privyAppId;
export const config_intercomAppId = configuration.intercomAppId;
export const config_apiEndpoint = configuration.apiEndpoint;
export const config_wsEndpoint = configuration.wsEndpoint;
export const config_gtmId = configuration.gtmId;
export const config_captableAiUrl = configuration.captableAiUrl;
export const config_etherscanOpUrl = configuration.etherscanOpUrl;
export const config_etherscanUrl = configuration.etherscanUrl;
export const config_basescanUrl = configuration.basescanUrl;
export const config_USDC = configuration.USDC;
export const config_smartContract = configuration.smartContract;
