import { useMemo } from "react";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useDisableFairmintSpinner } from "src/hooks";

// This page is used to redirect old urls to the settings page
const Company = () => {
  const [searchParams] = useSearchParams();
  const { portalId } = useParams();
  useDisableFairmintSpinner();

  const url = useMemo(() => {
    const tab = searchParams.get("tab");
    return tab ? `/p/${portalId}/settings?tab=${tab}` : `/p/${portalId}/settings`;
  }, [searchParams, portalId]);

  return (
    <Navigate to={url} replace />
  );
};

export default Company;
