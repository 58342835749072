import { CircularProgress, Box } from "@mui/material";

import Logo from "src/assets/images/default/FairmintLogo.svg";

function GradientCircularProgress() {
  return (
    <>
      <svg width={0} height={0}>
        <defs>
          <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ "svg circle": { stroke: "url(#my_gradient)" } }} />
    </>
  );
}

function FairmintLogoLoadingSpinner() {
  return (
    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <img src={Logo} alt="Fairmint logo" style={{ height: "3rem", marginBottom: "1rem" }} />
        <GradientCircularProgress />
      </Box>
    </Box>
  );
}
export default FairmintLogoLoadingSpinner;
