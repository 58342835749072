import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge, IconButton } from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";

import { usePopover, useWebSocket } from "src/hooks";
import { useGetTasks } from "src/services/getTasks";
import { WEBSOCKET_EVENT } from "src/constants";
import withAccountInfo from "src/hoc/withAccountInfo";

import { TaskPopover } from "./TaskPopover";

const NotificationsButton = ({ portalItem }) => {
  const popover = usePopover();
  const { portalId } = useParams();
  const { data: tasksInfo, mutate: reloadTasks } = useGetTasks({ portalId });

  const { lastMessage } = useWebSocket();

  useEffect(() => {
    const message = lastMessage?.data;
    if (message) {
      try {
        const data = JSON.parse(message);
        if (data.event === WEBSOCKET_EVENT.ADMIN_TASK_UPDATED) {
          reloadTasks();
        }
      }
      catch (error) {
        console.error("Failed to parse message data in notificationsButton:", message);
        console.error("Error:", error);
      }
    }
  }, [lastMessage, reloadTasks]);

  return (
    <>
      <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
        <Badge badgeContent={tasksInfo?.taskItems?.length} color="error">
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>
      <TaskPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        taskItems={tasksInfo?.taskItems || []}
        portalItem={portalItem}
        open={popover.open}
      />
    </>
  );
};

NotificationsButton.propTypes = {
  portalItem: PropTypes.object,
};

export default withAccountInfo(NotificationsButton);
