import { usePrivy } from "@privy-io/react-auth";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { ROUTE_PATH } from "src/constants";

const GuestGuard = (props) => {
  const { children } = props;
  const { authenticated, ready } = usePrivy();

  if (!ready) {
    return null;
  }

  if (authenticated) {
    return <Navigate to={ROUTE_PATH.PORTALS} replace />;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // not authenticated / authorized.

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
