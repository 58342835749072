import { usePrivy } from "@privy-io/react-auth";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import { ROUTE_PATH } from "src/constants";

const AuthGuard = (props) => {
  const { children } = props;
  const { ready, authenticated } = usePrivy();

  if (!ready) {
    return null;
  }

  if (!authenticated) {
    return <Navigate to={ROUTE_PATH.LOGIN} replace />;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
