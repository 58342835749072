import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import ReloadSVG from "src/assets/images/errors/reload.svg";

const ReloadPage = () => {
  const mdUp = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Box
      component="main"
      sx={{ alignItems: "center", display: "flex", flexGrow: 1, py: "80px" }}
    >
      <Container maxWidth="lg">
        <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
          <Box
            alt="Not found"
            component="img"
            src={ReloadSVG}
            sx={{
              height   : "auto",
              maxWidth : "100%",
              width    : 100,
            }}
          />
        </Box>
        <Typography align="center" variant={mdUp ? "h1" : "h5"}>
          New Updates Available
        </Typography>
        <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
          Life is like a box of chocolates. You never know what you’re gonna get.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
          <Button variant="contained" onClick={() => window.location.reload()}>
            Refresh now
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ReloadPage;
